<template>
<div>
  <div id="qr-code-full-region"></div>
</div>

</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref, watch } from '@vue/runtime-core';
import { Html5Qrcode } from 'html5-qrcode';

export default {
  name: "QRCodeScanner",
  props: {
    qrboxwidth: {
      type: Number,
      default: 250
    },
    qrboxheight: {
      type: Number,
      default: 250
    },
    fps: {
      type: Number,
      default: 10
    },
    switchCameraTrigger: {
      type: Number,
      default: 0
    }
  },
  emits: ['result'],
  components: {
   // MiniStack
   },
  setup(props,context) {

    let keyinput = "";

    function keylistener(e){
        if (e.key == "Enter"){
          onScanSuccess(keyinput,{keyinput});
          keyinput = "";
          return
        }
        keyinput += e.key
    }

    onMounted(()=>{
      keyinput = "";
      // This will trigger user permissions
      Html5Qrcode.getCameras().then(devices => {
        if (devices && devices.length) {
          cameraList.value = devices.map((d)=>d.id)
          if (cameraList.value.length > 1){activeCameraIndex.value = 1}
          startScanner(cameraList.value[activeCameraIndex.value])
        }
      }).catch(err => {
        console.error("error getting camera access",err);
      });

      document.addEventListener("keypress",keylistener)
    })

    onBeforeUnmount(()=>{
      document.removeEventListener("keypress",keylistener);
      stopScanner();
    })

    const cameraList = ref([]);
    const activeCameraIndex = ref(0);

    watch(computed(()=>props.switchCameraTrigger),()=>{switchCamera()})

    const switchCamera = ()=>{
      console.log("switching camera");
      if (cameraList.value.length > activeCameraIndex.value + 1){
        console.log("from",activeCameraIndex.value,"to",activeCameraIndex.value+1);
        activeCameraIndex.value = activeCameraIndex.value+1;
      }
      else{
        activeCameraIndex.value = 0;
      }
      startScanner(cameraList.value[activeCameraIndex.value]);
    }

    let html5QrCode;

    const stopScanner = async ()=>{
      if (html5QrCode?.stop){
        await html5QrCode.stop().then(() => {
          console.log("scanner off");
        }).catch((err) => {
          console.error("couldnt stop scanner",err);
        });
      }
      return true
    }

    const startScanner = async (deviceId)=>{
      stopScanner();

      html5QrCode = new Html5Qrcode(/* element id */ "qr-code-full-region");
      
      html5QrCode.start(
        deviceId, 
       // {facingMode:'environment'},
        {
          fps: props.fps,
          qrbox: { width: props.qrboxwidth, height: props.qrboxheight},  // Optional, if you want bounded box UI
          //aspectRatio: { ideal: 1.7777777778 } // hides the qrbox weirdly
        },
        (decodedText, decodedResult) => {
          onScanSuccess(decodedText,decodedResult)
        })
      .catch((err) => {
        console.error("could not start QR Scanner",err);
        alert("could not start scanner",JSON.stringify(err))
      });
    }

    const onScanSuccess = (decodedText, decodedResult) => {
      context.emit('result', decodedText, decodedResult);
    }

      return {onScanSuccess,switchCamera};
  },
}
</script>
